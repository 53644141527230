
import { React, useRef } from 'react'

import guruji from './Guru-Ji.jpg'
import logo from './logo.gif'
import banner from './banner.jpg'
import ldm from './ldm.jpg'
import instagram from './instagram.png'
import whataspp from './whatsapp.png'
import youtube from './youtube.png'

import yoga from './yoga.jpg'
import guru from './guru.jpg'
import newsletter from './newsletter-icon.png'
import mandala from './mandala.png'
import one from './one.mov'
import two from './two.mov'
import third from './third.mov'
import fouth from './fourth.mov'
import fifth from './fifth.mov'
import sixth from './sixth.mov'
import seventh from './seventh.mov'
import eight from './eight.mov'
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from './config'
import ContactForm from './ContactForm'

export default function App() {
  const captchaRef = useRef(null)

  const notify = () => toast("We will get back to you soon");
  const contactNotify = () => toast("Please fill all the details");
  const mobileNotify = () => toast("Please fill mobile number correctly");

  const [first_name, setName] = useState("")
  const [last_name, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [gender, setGender] = useState("")
  const [phone, setPhone] = useState("")
  const [age, setAge] = useState("")


  const [illness, setIllness] = useState("")
  const mudra = useRef(null);
  const LDMRef = useRef(null);
  const formRef = useRef(null);
  const dvineRef = useRef(null)
  const topScroll = useRef(null)
  const aboutRef = useRef(null)
  const [isChecked, setIsChecked] = useState(false);

  // Handle change event

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 400);
  return (
    <>
      <ToastContainer></ToastContainer>
      <div ref={topScroll}
        id="navbar"
        className="z-10 fixed top-0 left-0 w-full bg-white shadow md:hidden-navbar"
      >
        <div className="w-[80%] mx-auto px-4 py-2">
          <nav className="flex items-center justify-center">
            <img src={logo} alt="" className="w-[200px]" />
          </nav>
        </div>
      </div>
      <div className="relative">
        <div>
          <img src={banner} alt="" />
        </div>
        <img
          src={logo}
          alt=""
          className="absolute top-5 px-[60px] py-[60px] w-[250px] hidden md:block"
        />
      </div>
      {/* whatsapp icon */}
      <div className="whtsp fixed bottom-[15px] left-[15px] cursor-pointer z-10" onClick={(e) => { window.location.href = "https://api.whatsapp.com/send/?phone=919772956555&text&type=phone_number&app_absent=0" }}>
        <div className="flex justify-center items-center">
          <p className="text px-4 leading-6 text-base bg-green-500 text-white rounded-lg mx-4 order-1 hidden">
            WhatsApp us
          </p>
          <svg
            style={{
              pointerEvents: "none",
              display: "block",
              height: 50,
              width: 50
            }}
            width="50px"
            height="50px"
            viewBox="0 0 1024 1024"
          >
            <defs>
              <path
                id="htwasqicona-chat"
                d="M1023.941 765.153c0 5.606-.171 17.766-.508 27.159-.824 22.982-2.646 52.639-5.401 66.151-4.141 20.306-10.392 39.472-18.542 55.425-9.643 18.871-21.943 35.775-36.559 50.364-14.584 14.56-31.472 26.812-50.315 36.416-16.036 8.172-35.322 14.426-55.744 18.549-13.378 2.701-42.812 4.488-65.648 5.3-9.402.336-21.564.505-27.15.505l-504.226-.081c-5.607 0-17.765-.172-27.158-.509-22.983-.824-52.639-2.646-66.152-5.4-20.306-4.142-39.473-10.392-55.425-18.542-18.872-9.644-35.775-21.944-50.364-36.56-14.56-14.584-26.812-31.471-36.415-50.314-8.174-16.037-14.428-35.323-18.551-55.744-2.7-13.378-4.487-42.812-5.3-65.649-.334-9.401-.503-21.563-.503-27.148l.08-504.228c0-5.607.171-17.766.508-27.159.825-22.983 2.646-52.639 5.401-66.151 4.141-20.306 10.391-39.473 18.542-55.426C34.154 93.24 46.455 76.336 61.07 61.747c14.584-14.559 31.472-26.812 50.315-36.416 16.037-8.172 35.324-14.426 55.745-18.549 13.377-2.701 42.812-4.488 65.648-5.3 9.402-.335 21.565-.504 27.149-.504l504.227.081c5.608 0 17.766.171 27.159.508 22.983.825 52.638 2.646 66.152 5.401 20.305 4.141 39.472 10.391 55.425 18.542 18.871 9.643 35.774 21.944 50.363 36.559 14.559 14.584 26.812 31.471 36.415 50.315 8.174 16.037 14.428 35.323 18.551 55.744 2.7 13.378 4.486 42.812 5.3 65.649.335 9.402.504 21.564.504 27.15l-.082 504.226z"
              />
            </defs>
            <linearGradient
              id="htwasqiconb-chat"
              gradientUnits="userSpaceOnUse"
              x1="512.001"
              y1=".978"
              x2="512.001"
              y2="1025.023"
            >
              <stop offset={0} stopColor="#61fd7d" />
              <stop offset={1} stopColor="#2bb826" />
            </linearGradient>
            <use
              xlinkHref="#htwasqicona-chat"
              overflow="visible"
              style={{ fill: "url(#htwasqiconb-chat)" }}
              fill="url(#htwasqiconb-chat)"
            />
            <g>
              <path
                style={{ fill: "#FFFFFF" }}
                fill="#FFF"
                d="M783.302 243.246c-69.329-69.387-161.529-107.619-259.763-107.658-202.402 0-367.133 164.668-367.214 367.072-.026 64.699 16.883 127.854 49.017 183.522l-52.096 190.229 194.665-51.047c53.636 29.244 114.022 44.656 175.482 44.682h.151c202.382 0 367.128-164.688 367.21-367.094.039-98.087-38.121-190.319-107.452-259.706zM523.544 808.047h-.125c-54.767-.021-108.483-14.729-155.344-42.529l-11.146-6.612-115.517 30.293 30.834-112.592-7.259-11.544c-30.552-48.579-46.688-104.729-46.664-162.379.066-168.229 136.985-305.096 305.339-305.096 81.521.031 158.154 31.811 215.779 89.482s89.342 134.332 89.312 215.859c-.066 168.243-136.984 305.118-305.209 305.118zm167.415-228.515c-9.177-4.591-54.286-26.782-62.697-29.843-8.41-3.062-14.526-4.592-20.645 4.592-6.115 9.182-23.699 29.843-29.053 35.964-5.352 6.122-10.704 6.888-19.879 2.296-9.176-4.591-38.74-14.277-73.786-45.526-27.275-24.319-45.691-54.359-51.043-63.543-5.352-9.183-.569-14.146 4.024-18.72 4.127-4.109 9.175-10.713 13.763-16.069 4.587-5.355 6.117-9.183 9.175-15.304 3.059-6.122 1.529-11.479-.765-16.07-2.293-4.591-20.644-49.739-28.29-68.104-7.447-17.886-15.013-15.466-20.645-15.747-5.346-.266-11.469-.322-17.585-.322s-16.057 2.295-24.467 11.478-32.113 31.374-32.113 76.521c0 45.147 32.877 88.764 37.465 94.885 4.588 6.122 64.699 98.771 156.741 138.502 21.892 9.45 38.982 15.094 52.308 19.322 21.98 6.979 41.982 5.995 57.793 3.634 17.628-2.633 54.284-22.189 61.932-43.615 7.646-21.427 7.646-39.791 5.352-43.617-2.294-3.826-8.41-6.122-17.585-10.714z"
              />
            </g>
          </svg>
        </div>
      </div>
      {/* scroll to top button  */}
      <div className="bg-[#f7972f] flex justify-center items-center text-white fixed bottom-[15px] right-[15px] w-[50px] h-[50px] cursor-pointer z-10 mb-16 " onClick={(e) => {
        scrollToRef(topScroll)
      }}>
        <div className="">
          <i className="fa-solid fa-angle-up" />
        </div>
      </div>
      {/* about us section  */}
      <div className="bg-[#fafafa] py-[80px] px-[20px] md:px-0">
        <div className=":w-[100%] md:w-[70%] mx-auto flex md:flex-row flex-col">
          <div className="flex items-center md:pl-5 md:w-[50%]">
            <div className="md:pr-[13%] md:pb-[7%] pr-0 pb-0">
              <div ref={aboutRef}>
                <h2 className="uppercase text-3xl font-semibold tracking-wide leading-8 text-[#505050;]">
                  About Gurudev
                </h2>
              </div>
              <div
                style={{
                  borderColor: "#ff9c54",
                  width: 55,
                  borderBottomWidth: 2,
                  marginTop: 21,
                  marginBottom: 10
                }}
              ></div>
              <div>
                <p className="mt-[10px] leading-7">
                  Gurudev Shri Y.K Sharma is a great visionary &amp; his
                  far-sightedness saw the increasing stress level in the lives of
                  the general public. His passion for the betterment of people’s
                  health and bent towards ancient age-old practices made him revisit
                  the ancient power of the East and the scientific approach of the
                  West to create new techniques and protocols in the field of
                  Meditation &amp; Yogic Kriya and wanted to bring a paradigm shift,
                  distinguished from state of the art, among all races and section
                  of india and worldwide.
                </p>
              </div>
            </div>
          </div>
          <div className="md:w-[50%]">
            <img src={guruji} alt="" />
          </div>
        </div>
      </div>
      {/* revive and divine section  */}
      <div className="py-[80px] bg-black ">
        <div className="md:w-[70%] mx-auto">
          <div className="md:flex">
            <div className="md:w-[50%] mb-5 md:mb-0">
              <div className="px-[15px]">

                <video
                  className="border-4 border-[#EA7E09] rounded-lg"
                  height="auto"
                  controls="true"

                >

                  <source
                    src={one}
                    type="video/mp4"
                  />
                </video>
              </div>

            </div>
            <div className="md:w-[50%]">
              <div className="md:px-[15px]">
                <div className="px-[25px] pb-[5%] pt-[2 %]">
                  <h2>
                    <span className="text-white text-[25px]">
                      Revive &amp; Divine Yourself
                    </span>
                  </h2>
                  <div
                    className="separator"
                    style={{
                      borderColor: "#ffffff",
                      width: 55,
                      borderBottomWidth: 2,
                      marginTop: 21,
                      marginBottom: 10
                    }}
                  />
                  <div>
                    <p className="text-white mt-[10px] leading-7">
                      Our effort is to make aware about the art of healthy living by
                      changing the lifestyle pattern which strengthen the body,
                      promote vitality and restores good health. Yogashray Sewayatan
                      is thus dedicated to the thought of public welfare by
                      providing health &amp; well-being to all. This is the greatest
                      ideal in the world. With the mission &amp; vision of healthy
                      &amp; happy life to all Yogashray Sewayatan provides free of
                      cost online sessions at Pranamaya Kosha (Vital Energy Level)
                      and Manomaya Kosha (Mind Level)
                    </p>
                  </div>
                  <br />
                  <button className="bg-[#f7972f] text-white uppercase font-semibold px-[30px] py-[10px] hover:bg-[#ea7e09]" onClick={(e) => { scrollToRef(formRef) }}>
                    CONTACT NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* law of nature  */}
      <div className="py-[80px] bg-[#fafafa]">
        <div className="md:w-[50%] mx-auto px-[15px]">
          <div className="text-center">
            <span
              className="separator-left"
              style={{ borderTop: "1px solid #f7972f" }}
            />
            <span className="custom-image">
              <img
                width={55}
                height={54}
                src={mandala}
                className="attachment-full "
                alt="a"
              />
            </span>
            <span
              className="separator-right"
              style={{ borderTop: "1px solid #f7972f" }}
            />
          </div>
          <br />
          <div className="mt-4">
            <video
              className="border-4 rounded-lg border-[#EA7E09]"
              height="auto"
              controls="true"
            >
              <source src={two} />
            </video>
          </div>
        </div>
      </div>
      {/* ldm  */}
      <div className="py-[80px] " ref={LDMRef} >
        <div className="md:w-[70%] mx-auto px-[15px]">
          <div className="text-center">
            <span
              className="separator-left"
              style={{ borderTop: "1px solid #f7972f" }}
            />
            <span className="custom-image">
              <img
                width={55}
                height={54}
                src={mandala}
                className="attachment-full "
                alt="a"
              />
            </span>
            <span
              className="separator-right"
              style={{ borderTop: "1px solid #f7972f" }}
            />
          </div>
          <br />
          <div className="md:px-[5%]">
            <img src={ldm} alt="" className="mx-auto" />
          </div>
          <br />
          <div className="md:px-[20%]">
            <div>
              <p className="text-center text-lg">
                Free online “LDM” Meditation Technique – session daily at 08:00 AM
              </p>
            </div>
            <div>
              <h3 className="mt-[10px] text-center uppercase text-lg tracking-wide font-semibold text-[#505050]">
                Learn &amp; Practice the Art of Mastering the Mind at Yogashray
                Sewayatan Meditation Retreat to Heal Mind, Body &amp; Soul
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* ldm technique  */}
      <div className=" bg-[#fafafa] md:flex px-5 md:px-0">
        <div className="md:w-[50%]">
          <video
            className="border-4 border-[#EA7E09] rounded-lg w-full "
            height="auto"
            controls="true"
          >
            <source src={third} />
          </video>
        </div>
        <div className="md:w-[50%] my-auto">
          <div className="md:pl-[13%] pt-[60px] pb-[40px] md:pr-[16%]">
            <div>
              <h2 className="uppercase text-3xl font-semibold tracking-wide leading-8 text-[#505050;]">
                LDM Technique
              </h2>
            </div>
            <div
              style={{
                borderColor: "#ff9c54",
                width: 55,
                borderBottomWidth: 2,
                marginTop: 21,
                marginBottom: 10
              }}
            ></div>
            <div>
              <p className="mt-[10px] leading-7">
                We bring you our skilfully developed meditation i.e. LDM Technique
                (Longduration Deep Meditation) to control the chaotic energy of the
                mind and emotions through meditation to find inner peace. LDM
                technique is an art of mastering the mind which leads to the
                ultimate pathway to reach the higher healing state of mind.
                Yogashray Sewayatan has researched and developed LDM technique
                <a href="https://www.yogashraysewayatan.com">
                  <strong className="text-[#f7972f]">
                    Meditation centre in India
                  </strong>
                </a>
                . With a vision that when you meditate, you are fully awake,
                exploring your inner dimensions, letting go of the stress from your
                body allowing yourself to experience your breathing. When the mind
                is silent and no longer is distractions there, meditation deepens.
                Its antidote to the stress of modern world.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ldm meditation  */}
      <div className=" bg-[#fafafa] flex flex-col-reverse md:flex-row px-5 md:px-0">
        <div className="md:w-[50%] my-auto">
          <div className=" md:pl-[13%] pt-[60px] pb-[40px] md:pr-[16%]">
            <div>
              <h2 className="uppercase text-3xl font-semibold tracking-wide leading-8 text-[#505050;]">
                LDM Meditation
              </h2>
            </div>
            <div
              style={{
                borderColor: "#ff9c54",
                width: 55,
                borderBottomWidth: 2,
                marginTop: 21,
                marginBottom: 10
              }}
            ></div>
            <div>
              <p className="mt-[10px] leading-7">
                It draws us closer to our higher resources of strength,
                understanding and self- control. Connecting with these inner
                potentials increase our ability to cope up with depression,
                insomnia, anxiety and the situations that discourage us. LDM
                Technique helps in alleviating hypotension and hypertension by
                allowing the blood vessels to relax and improve blood circulation.
                Regular meditation practices boost brain power reaching deeper state
                of awareness affect the action of hormones, neurotransmitters in the
                body.
              </p>
            </div>
          </div>
        </div>
        <div className="md:w-[50%]">
          <video
            className="border-4 border-[#EA7E09] rounded-lg w-full "
            height="auto"
            controls="true"
          >
            <source src={fouth} />
          </video>
        </div>
      </div>
      {/* heading not given  */}
      <div className=" bg-[#fafafa] flex flex-col md:flex-row px-5 md:px-0">
        <div className="md:w-[50%]">
          <video
            className="border-4 border-[#EA7E09] rounded-lg w-full "
            height="auto"
            controls="true"
          >
            <source src={fifth} />{" "}
          </video>
        </div>
        <div className="md:w-[50%] my-auto">
          <div className="md:pl-[13%] pt-[60px] pb-[40px] md:pr-[16%]">
            <div>
              <h2 className="uppercase text-3xl font-semibold tracking-wide leading-8 text-[#505050;]" />
            </div>
            <div
              style={{
                borderColor: "#ff9c54",
                width: 55,
                borderBottomWidth: 2,
                marginTop: 21,
                marginBottom: 10
              }}
            ></div>
            <div>
              <p className="mt-[10px] leading-7">
                The process of controlling the mind through LDM Technique increase
                mental strength, resilience, concentration, memory retention and
                recall. It aids in better information processing, better cognitive
                skills, creative thinking, better decision making and problem
                solving by alleviating deep seated stress, anxiety and depression.
                With all the stress gone from our body and our anabolic process of
                rejuvenation and renewal enhanced, the ageing process is naturally
                slowed down and feels more energized and youthful.
              </p>
            </div>
            <br />
            <button className="bg-[#f7972f] text-white uppercase font-semibold px-[30px] py-[10px] hover:bg-[#ea7e09]" onClick={(e) => { scrollToRef(formRef) }}>
              SIGN UP
            </button>
          </div>
        </div>
      </div>
      {/* mudra pranayam  */}
      <div className="py-[80px]" ref={mudra}>
        <div className="md:w-[70%] mx-auto md:px-[15px]">
          <div className="text-center">
            <span
              className="separator-left"
              style={{ borderTop: "1px solid #f7972f" }}
            />
            <span className="custom-image">
              <img
                width={55}
                height={54}
                src={mandala}
                className="attachment-full "
                alt="a"
              />
            </span>
            <span
              className="separator-right"
              style={{ borderTop: "1px solid #f7972f" }}
            />
          </div>
          <br />
          <div className="md:px-[20%] mt-5" >
            <div>
              <h1 className="text-center uppercase leading-10 mb-[15px] text-4xl tracking-wider font-semibold text-[#505050] font-serif ">
                Mudra Pranayam
              </h1>
            </div>
            <div className="mt-[10px]">
              <h3 className="text-center uppercase text-lg tracking-wide font-semibold text-[#505050]">
                TECHNIQUE OF YOGIC BREATHING
              </h3>
            </div>
            <div>
              <h3 className="mt-[10px] text-center uppercase text-lg tracking-wide font-semibold text-[#505050]">
                LEARN &amp; PRACTICE THE ART OF BREATHING AT YOGASHRAY SEWAYATAN
                MUDRA PRANAYAM RETREAT FOR HAPPY &amp; HEALTHY LIFE
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/*   */}
      <div className=" bg-[#fafafa] md:flex">
        <div className="md:w-[50%]">
          <img src={guru} alt="" />
        </div>
        <div className="md:w-[50%] my-auto">
          <div className="px-5 md:pl-[13%] pt-[60px] pb-[40px] md:pr-[16%]">
            <div>
              <h2 className="uppercase text-3xl font-semibold tracking-wide leading-8 text-[#505050;]" />
            </div>
            <div
              style={{
                borderColor: "#ff9c54",
                width: 55,
                borderBottomWidth: 2,
                marginTop: 21,
                marginBottom: 10
              }}
            ></div>
            <div>
              <p className="mt-[10px] leading-7">
                Life is the period between one breath and the next. Mudra Pranayam
                is the technique of breath control and it increases pranic flow in
                our body. Mudra Pranayam is useful to regulate our breath &amp;
                vital force. Those who have mastered the art of breathing have
                mastered the art of living.
              </p>
            </div>
            <br />
            <button className="bg-[#f7972f] text-white uppercase font-semibold px-[30px] py-[10px] hover:bg-[#ea7e09]" onClick={(e) => { scrollToRef(formRef) }}>
              SIGN UP
            </button>
          </div>
        </div>
      </div>
      <div className=" bg-[#fafafa] px-5 md:px-0 flex flex-col-reverse md:flex-row">
        <div className="md:w-[50%] my-auto">
          <div className="md:pl-[13%] pt-[60px] pb-[40px] md:pr-[16%]">
            <div>
              <h2 className="uppercase text-3xl font-semibold tracking-wide leading-8 text-[#505050;]" />
            </div>
            <div
              style={{
                borderColor: "#ff9c54",
                width: 55,
                borderBottomWidth: 2,
                marginTop: 21,
                marginBottom: 10
              }}
            ></div>
            <div>
              <p className="mt-[10px] leading-7">
                Life is the period between one breath and the next. Mudra Pranayam
                is the technique of breath control and it increases pranic flow in
                our body. Mudra Pranayam is useful to regulate our breath &amp;
                vital force. Those who have mastered the art of breathing have
                mastered the art of living.
              </p>
            </div>
            <br />
            <button className="bg-[#f7972f] text-white uppercase font-semibold px-[30px] py-[10px] hover:bg-[#ea7e09]" onClick={(e) => { scrollToRef(formRef) }}>
              SIGN UP
            </button>
          </div>
        </div>
        <div className="md:w-[50%]">
          <video
            className="border-4 border-[#EA7E09] rounded-lg w-full "
            height="auto"
            controls="true"
          >
            <source src={sixth} />{" "}
          </video>
        </div>
      </div>
      <div className=" bg-[#fafafa] md:flex ">
        <div className="md:w-[50%]">
          <img src={yoga} alt="" />
        </div>
        <div className="md:w-[50%] my-auto">
          <div className="md:pl-[13%] pt-[60px] pb-[40px] md:pr-[16%] px-5 md:px-0">
            <div>
              <h2 className="uppercase text-3xl font-semibold tracking-wide leading-8 text-[#505050;]" />
            </div>
            <div
              style={{
                borderColor: "#ff9c54",
                width: 55,
                borderBottomWidth: 2,
                marginTop: 21,
                marginBottom: 10
              }}
            ></div>
            <div>
              <p className="mt-[10px] leading-7">
                Human body is made up of panchmahabhut – five elements of nature
                which regulate the normal functions of the body. Imbalance of any of
                the elements may cause health disorders. Regular practice of Mudra
                with Pranayam balances the five elements that leads to improve blood
                circulation &amp; metabolism. Mudra Pranayam is helpful in Stress,
                Insomnia, Depression, Respiratory disorder, Hypertension,
                Hypotension, Joint Pain, &amp; Stomach disorder etc. Pranayam brings
                alertness, activeness &amp; selfconfidence. It also helps to recover
                faster from illness and strengthen the immunity system. Pranayam is
                the easiest way to detoxify our body.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* DIVINE LIFE – SUTRA */}
      <div className="py-[80px] ">
        <div className="md:w-[70%] mx-auto px-[15px]">
          <div className="text-center">
            <span
              className="separator-left"
              style={{ borderTop: "1px solid #f7972f" }}
            />
            <span className="custom-image">
              <img
                width={55}
                height={54}
                src={mandala}
                className="attachment-full "
                alt="a"
              />
            </span>
            <span
              className="separator-right"
              style={{ borderTop: "1px solid #f7972f" }}
            />
          </div>
          <br />
          <div className="px-5 md:px-[20%] mt-5" ref={dvineRef}>
            <div>
              <h1 className="text-center uppercase leading-10 mb-[15px] text-4xl tracking-wider font-semibold text-[#505050] font-serif ">
                DIVINE LIFE – SUTRA
              </h1>
            </div>
            <br />
            <div className="mt-[10px]">
              <h3 className="text-center uppercase text-lg tracking-wide font-semibold text-[#505050]">
                FOR HAPPY &amp; PEACEFUL LIFE JOURNEY
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-[#fafafa] flex flex-col md:flex-row">
        <div className="md:w-[50%]  flex items-center">
          <div className="px-[20px]">
            <video
              className="border-4 border-[#EA7E09] rounded-lg w-full "
              height="auto"
              controls="true"
            >
              <source src={seventh} />{" "}
            </video>
          </div>
        </div>
        <div className="md:w-[50%] md:px-0 px-5">
          <div className="py-[80px] md:pl-[13%] md:pr-[16%]">
            <div
              style={{
                borderColor: "#ff9c54",
                width: 55,
                borderBottomWidth: 2,
                marginTop: 21,
                marginBottom: 10
              }}
            ></div>
            <div>
              <p className="mt-[10px] leading-7">
                Attitude &amp; action of the individual play important role in
                his/her day to day life and at times one may face such situations
                where we do not know what is right &amp; what is wrong and what to
                do. By knowing and understanding the philosophy of life through
                Divine Life – Sutra one can make life meaningful, harmonious and
                peaceful.{" "}
              </p>
            </div>
            <br />
            <button className="bg-[#f7972f] text-white uppercase font-semibold px-[30px] py-[10px] hover:bg-[#ea7e09]" onClick={(e) => { scrollToRef(formRef) }}>
              SIGN UP
            </button>
          </div>
        </div>
        <div className="md:w-[50%] flex items-center">
          <div className="px-[20px] my-auto">
            <video
              className="border-4 border-[#EA7E09] rounded-lg w-full "
              height="auto"
              controls="true"
            >
              <source src={eight} />{" "}
            </video>
          </div>
        </div>
      </div>
      {/* register  */}
      <div className="py-[80px]">
        <div className="W-[70%] mx-auto">
          <div className="text-center">
            <span
              className="separator-left"
              style={{ borderTop: "1px solid #f7972f" }}
            />
            <span className="custom-image">
              <img
                width={55}
                height={54}
                src={mandala}
                className="attachment-full "
                alt="a"
              />
            </span>
            <span
              className="separator-right"
              style={{ borderTop: "1px solid #f7972f" }}
            />
          </div>
          <br />
          <div className="mb-[30px]" ref={formRef}>
            <h1 className="mt-5 text-center uppercase leading-10 mb-[15px] text-4xl tracking-wider font-semibold text-[#505050] ">
              Register now
            </h1>
          </div>
          <ContactForm />
        </div>
      </div>
      {/* footer */}
      <div className="footer bg-[url('./img/footer-background-img.jpg')]">
        <div className="px-5 md:px-0 py-10 md:py-[96px] md:w-[70%] mx-auto md:flex">
          <div className="mt-5 md:mt-0 md:px-[15px] flex-1">
            <h3 className="text-2xl text-white uppercase font-semibold mb-[23px] tracking-wide">
              About US
            </h3>
            <div>
              <p className="text-[#e5e4e4] leading-7">
                Gurudev Shri Y.K Sharma is a great visionary &amp; his
                far-sightedness saw the increasing stress level in the lives of the
                general public
              </p>
            </div>
            <div className="my-5">
              <button className="border border-white rounded-full text-white py-3 px-5" onClick={(e) => { scrollToRef(aboutRef) }}>
                Read More
              </button>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:px-[15px] flex-1">
            <h3 className="text-2xl text-white uppercase font-semibold mb-[23px] tracking-wide">
              Quick Links
            </h3>
            <div>
              <ul className="uppercase text-[#e5e4e4]">
                <li className="mb-5">
                  <a onClick={(e) => { scrollToRef(LDMRef) }}>LDM- Meditation</a>
                </li>
                <li className="mb-5">
                  <a onClick={(e) => { scrollToRef(mudra) }}>   Mudra Pranamay</a>

                </li>
                <li className="mb-5">
                  <a onClick={(e) => { scrollToRef(dvineRef) }} >
                    DIVINE LIFE – SUTRA
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" />
                  Privacy Policy
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:px-[15px] flex-1">
            <h3 className="text-2xl text-white uppercase font-semibold mb-[23px] tracking-wide">
              Contact US
            </h3>
            <div className="mb-5 text-[#e5e4e4]">
              <i className="fa-solid fa-house" />
              <span className="text-[#e5e4e4]" /> Keshopura, Bhankrota, Ajmer Road,
              Jaipur
            </div>
            <div className="mb-5 text-[#e5e4e4]">
              <i className="fa-solid fa-phone" />
              <span className="text-[#e5e4e4]" /> +919772947555 / +919772956555
            </div>
            <div className='flex gap-3 '>
              <a href='https://www.youtube.com/@yogashraysewayatan'> <img src={youtube} className='w-10'></img></a>
              <a href='https://api.whatsapp.com/send/?phone=919772956555&text&type=phone_number&app_absent=0'> <img src={whataspp} className='w-10'></img></a>
              <a href='https://www.instagram.com/yogashraysewayatan/'> <img src={instagram} className='w-10'></img></a>
            </div>
          </div>
          <div className="md:px-[15px]">
            <h3 className="text-2xl text-white uppercase font-semibold mb-[23px] tracking-wide">
              Subscrible
            </h3>
            <div className="flex items-center bg-[#2b2c32] rounded">
              <input
                type="email"
                placeholder="Enter Your Email"
                className="bg-[#2b2c32] text-white px-5 py-3 rounded-l outline-none focus:ring-2 focus:ring-gray-600"
              />
              <button className="bg-[#3e4048] text-gray-300 px-5 py-3 rounded-r">
                <img src={newsletter} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}
