import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <GoogleReCaptchaProvider 
    reCaptchaKey="6LflDBUqAAAAAM2men7ksykyyR8euV7YqJo5EO0F"
  >
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);
